import isBid from '@/filters/listing/is-bid';
import { useCompanyFactoryStore } from '@/stores';
import { ListingPreview } from '@/types';

export default (listing: ListingPreview) => {
  if (!isBid(listing)) {
    return true;
  }

  return useCompanyFactoryStore().hasAnyByProduct(
    listing.factories,
    listing.productId
  );
};
