<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog @close="closeModal()">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogPanel
          class="fixed inset-0 z-30 flex items-center justify-center bg-black/30"
          data-testid="modal"
          @click.self="closeModal()"
        >
          <div
            class="flex max-h-[95vh] min-w-[600px] flex-col overflow-y-auto rounded-xl bg-white shadow-2xl"
          >
            <header
              class="flex justify-between"
              :class="{
                'p-6': hasTitle || canForceClose,
              }"
            >
              <DialogTitle class="w-full font-medium">
                <slot name="header"></slot>
              </DialogTitle>

              <button
                v-if="canForceClose"
                class="rounded-md border px-2.5 py-1 font-circular text-[10px]/none"
                @click="$emit('update:isOpen', false)"
              >
                {{ $t('modal.esc') }}
              </button>

              <slot name="header-controls"></slot>
            </header>
            <section :class="bodyClass">
              <slot name="body"></slot>
            </section>
            <footer v-if="hasFooter" :class="footerClass">
              <slot name="footer"></slot>
            </footer>
          </div>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue';
import { PropType } from 'vue';

const props = defineProps({
  isOpen: Boolean,
  canForceClose: {
    type: Boolean,
    default: true,
  },
  hasTitle: {
    type: Boolean,
    default: true,
  },
  infoTextKey: {
    type: String as PropType<string | null>,
    default: null,
  },
  bodyClass: {
    type: String,
    default: 'overflow-y-auto p-6',
  },
  footerClass: {
    type: String,
    default: 'p-6 border-t border-vesper-mud',
  },
  hasFooter: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:isOpen']);

const closeModal = () => {
  if (props.canForceClose) {
    emit('update:isOpen', false);
  }
};
</script>
