<template>
  <RouterView />
  <Toast />
  <ModalDealAccepted />
  <OfflineIndicator />
</template>

<script setup lang="ts">
import ModalDealAccepted from '@/components/ModalDealAccepted.vue';
import OfflineIndicator from '@/components/OfflineIndicator.vue';
import Toast from '@/components/Toast.vue';
import {
  useApprovedFactoryStore,
  useApprovedTraderStore,
  useCompanyFactoryStore,
  useShipmentStore,
  useUserStore,
} from '@/stores';
import { useI18n } from '@/translations';
import { useTitle } from '@vueuse/core';
import { computed, watch } from 'vue';
import { RouterView, useRoute, useRouter } from 'vue-router';

const userStore = useUserStore();
const companyFactoryStore = useCompanyFactoryStore();
const approvedFactoryStore = useApprovedFactoryStore();
const shipmentStore = useShipmentStore();
const approvedTraderStore = useApprovedTraderStore();

const token = localStorage.getItem('_auth.token') || '';

userStore.token = token;

const router = useRouter();
watch(
  () => userStore.token,
  async (token) => {
    if (!token) {
      await router.push({ name: 'login' });
      userStore.reset();
      return;
    }

    await companyFactoryStore.load();
    await approvedFactoryStore.load();
    await shipmentStore.load();
    await approvedTraderStore.load();
  }
);

const route = useRoute();
const { t, exists } = useI18n();
const title = computed(() => {
  const pageKey = [
    `pages.${String(route.name)}.default`,
    `pages.${String(route.name)}`,
  ];

  if (!exists(pageKey)) {
    return 'Vesper';
  }

  const page = t(pageKey);

  return t('common.page-title', { page });
});

useTitle(title);
</script>
