import { CompanyFactory, ListingPreview } from '@/types';

export default (
  factories: CompanyFactory[],
  listing: ListingPreview
): CompanyFactory[] => {
  return factories
    .filter((factory) => {
      return listing.factories.includes(factory.id);
    })
    .filter((factory) => {
      return factory.products.includes(listing.productId);
    });
};
