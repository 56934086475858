import { z } from 'zod';

export const incoterm = [
  'EXW',
  'FCA',
  'CPT',
  'CIP',
  'DPU',
  'DAP',
  'DDP',
] as const;

export const timeSpans = ['MONTH', 'QUARTER'] as const;

export const incotermSchema = z.enum(incoterm, {
  message: 'Please select an incoterm',
});

export const timeSpanSchema = z.enum(timeSpans, {
  message: 'Please select a delivery date',
});

export const latestDeliverySchema = z.object(
  {
    timeSpan: timeSpanSchema,
    latestDelivery: z.string(),
  },
  { message: 'Please select a date' }
);

export const deliveryLocationSchema = z.object(
  {
    id: z.number(),
    name: z.string(),
  },
  { message: 'Please select a location' }
);
