import { useApi } from '@/api';
import { useUserStore } from '@/stores';
import { Product } from '@/types';
import { CompanyFactory } from '@/types/company';
import { captureException, withScope } from '@sentry/vue';
import { isEmpty } from 'lodash';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useApprovedFactoryStore = defineStore('approvedFactory', () => {
  const api = useApi();
  const approvedFactories = ref<CompanyFactory[]>([]);

  async function load() {
    if (!useUserStore().hasPermission('CUSTOMER/APPROVED_FACTORY.GET')) {
      return;
    }

    await api.company
      .listApprovedFactories()
      .then((loadedFactories) => (approvedFactories.value = loadedFactories))
      .catch((error) =>
        withScope((scope) => {
          scope.setLevel('warning');
          captureException(
            new Error('Failed to load approved factories', { cause: error })
          );
        })
      );
  }

  function filterByProduct(product: Product) {
    return approvedFactories.value.filter((factory) =>
      factory.products.includes(product.id)
    );
  }

  function hasAnyByProduct(factoryIds: number[], productId: number): boolean {
    if (isEmpty(factoryIds)) {
      return false;
    }

    return approvedFactories.value.some(
      (factory) =>
        factoryIds.includes(factory.id) && factory.products.includes(productId)
    );
  }

  function clear() {
    approvedFactories.value = [];
  }

  return {
    approvedFactories,
    clear,
    filterByProduct,
    hasAnyByProduct,
    load,
  };
});
