<template>
  <button
    class="group flex items-center"
    :disabled="!feedback"
    @click="openFeedbackForm()"
  >
    <div class="relative flex w-full items-center text-sm">
      <div
        class="relative flex size-7 shrink-0 items-center justify-center rounded-md text-vesper-purple group-hover:bg-gray-300"
        :class="{
          'mr-[23px]': showSidebar || sidebarHover,
        }"
      >
        <Icon icon="fa-regular fa-bug" fixed-width />
      </div>

      <div
        v-if="sidebarHover || showSidebar"
        class="text-vesper-blue/50 group-hover:text-vesper-purple"
      >
        {{ $t('sentry.report-bug') }}
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { getFeedback } from '@sentry/vue';
import { onMounted, ref } from 'vue';

defineProps<{
  sidebarHover: boolean;
  showSidebar: boolean;
}>();

const feedback = ref<ReturnType<typeof getFeedback> | null>(null);

onMounted(() => {
  feedback.value = getFeedback();
});

const openFeedbackForm = async () => {
  if (feedback.value) {
    const form = await feedback.value.createForm();
    form.appendToDom();
    form.open();
  }
};
</script>

<style>
#sentry-feedback {
  --inset: auto 65px 0 auto;
}
</style>
