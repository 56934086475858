<script lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import {
  faBullseyeArrow,
  faCalendarClock,
  faClipboardList,
  faHandshake,
  faList,
  faMessages,
  faPartyHorn,
  faWifiSlash,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRightArrowLeft,
  faArrowRightLong,
  faArrowTurnDownLeft,
  faArrowTurnLeft,
  faArrowUpRight,
  faArrowUpRightFromSquare,
  faBallPile,
  faBell,
  faBook,
  faBug,
  faButter,
  faCalendarMinus,
  faCheck,
  faCircleCheck,
  faCircleInfo,
  faClock,
  faCoins,
  faDash,
  faEllipsis,
  faEnvelope,
  faHouseBlank,
  faInfoCircle,
  faMagnifyingGlass,
  faMessage,
  faMessageQuestion,
  faMinus,
  faPhone,
  faPlus,
  faRectangleHistory,
  faSquare,
  faTriangleExclamation,
  faTurnDownLeft,
  faUser,
  faWallet,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCaretDown,
  faCaretUp,
  faCheck as faCheckSolid,
  faCircleExclamation,
  faExclamation,
  faEye,
  faEyeSlash,
  faHeadset,
  faInfo,
  faSpinner,
  faSquareCheck,
  faXmark as faXmarkSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const faIcons = {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRightArrowLeft,
  faArrowRightLong,
  faArrowTurnDownLeft,
  faArrowTurnLeft,
  faArrowUpRight,
  faArrowUpRightFromSquare,
  faBallPile,
  faBell,
  faBook,
  faBug,
  faBullseyeArrow,
  faButter,
  faCalendarClock,
  faCalendarMinus,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCheckSolid,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faClipboardList,
  faClock,
  faCoins,
  faDash,
  faEllipsis,
  faEnvelope,
  faExclamation,
  faEye,
  faEyeSlash,
  faHandshake,
  faHeadset,
  faHouseBlank,
  faInfo,
  faInfoCircle,
  faList,
  faMagnifyingGlass,
  faMessage,
  faMessageQuestion,
  faMessages,
  faMinus,
  faPartyHorn,
  faPhone,
  faPlus,
  faRectangleHistory,
  faSpinner,
  faSquare,
  faSquareCheck,
  faTriangleExclamation,
  faTurnDownLeft,
  faUser,
  faWallet,
  faWhatsapp,
  faWifiSlash,
  faXmark,
  faXmarkSolid,
};

library.add(faIcons);

export default FontAwesomeIcon;
</script>
