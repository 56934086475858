import { useApprovedTraderStore } from '@/stores';
import { ListingPreview } from '@/types';

export default (listing: ListingPreview) => {
  if (listing.owner.type !== 'TRADER') {
    return true;
  }

  return useApprovedTraderStore().isApprovedTrader(listing.owner);
};
