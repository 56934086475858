<template>
  <button
    class="group flex items-center text-sm"
    @click="panelCreateListingStore.openPanel"
  >
    <div
      class="relative flex size-7 shrink-0 flex-col items-center justify-center overflow-hidden rounded-md bg-vesper-neutral text-white"
      :class="{
        'mr-[23px]': showSidebar,
      }"
    >
      <Icon
        icon="fa-regular fa-plus"
        fixed-width
        class="translate-y-[7px] duration-200 ease-out group-hover:-translate-y-3.5"
      />
      <Icon
        icon="fa-regular fa-plus"
        fixed-width
        class="translate-y-3.5 text-vesper-green duration-200 ease-out group-hover:translate-y-[-7px]"
      />
    </div>
    <div
      v-if="showSidebar"
      data-test="menu-item-text"
      class="text-vesper-blue/50 group-hover:text-vesper-purple"
    >
      {{ $t('common.create-order', { type: orderType }) }}
    </div>
  </button>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import { usePanelCreateListingStore, useUserStore } from '@/stores';
import { t } from 'i18next';
import { computed } from 'vue';

defineProps({
  showSidebar: {
    type: Boolean,
    required: true,
  },
});

const panelCreateListingStore = usePanelCreateListingStore();
const userStore = useUserStore();
const orderType = computed(() => {
  switch (userStore.company?.type) {
    case 'BUYER':
      return t('common.BID');
    case 'SELLER':
      return t('common.OFFER');
    case 'TRADER':
      return t('common.bid-offer');
    default:
      return '';
  }
});
</script>
