<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div
    v-if="sidebarPinned && !lg"
    class="fixed inset-0 z-10 bg-vesper-stone opacity-50"
    @click="toggleSidebar()"
  ></div>
  <aside
    class="sidebar-bounce left-0 flex shrink-0 flex-col overflow-x-hidden whitespace-nowrap bg-vesper-stone transition-[width] duration-300"
    :class="[
      sidebarWidth,
      {
        'lg:static': sidebarPinned,
        'fixed z-20 h-full': !sidebarPinned || !lg,
      },
    ]"
    data-testid="app-sidebar"
    @mouseenter="sidebarHover = true"
    @mouseleave="sidebarHover = false"
  >
    <div class="border-b border-vesper-blue/5 py-[26px] pl-[22px]">
      <div class="flex">
        <img
          src="@/assets/images/logo/vesper-star.svg"
          class="h-5 w-7 shrink-0"
          alt="Vesper logo"
        />
        <img
          v-if="showSidebar"
          src="@/assets/images/logo/txt.svg"
          alt="Vesper logo"
          class="ml-[22px] h-5"
        />
      </div>
    </div>
    <nav class="flex h-full flex-col">
      <div class="space-y-4">
        <div class="space-y-4 border-b border-vesper-blue/5 px-[22px] py-4">
          <MenuItem
            v-for="product in productStore.products"
            :key="product.slug"
            :show-sidebar="showSidebar"
            :icon-left="getIcon(product.slug)"
            :to="{ name: 'overview', params: { productSlug: product.slug } }"
          >
            {{ $t(`pages.${product.slug}`) }}
          </MenuItem>
        </div>
        <div class="space-y-4 px-[22px]">
          <MenuItem
            v-for="item in mainNavItems"
            :key="item.key"
            :show-sidebar="showSidebar"
            :icon-left="item.icon"
            :to="item.to"
          >
            {{ $t(`pages.${item.key}`) }}
          </MenuItem>

          <CreateOrderMenuItem :show-sidebar="showSidebar" />
        </div>
      </div>
      <div class="mt-auto space-y-4 px-[22px] py-4">
        <MenuItem
          v-for="item in supportNavItems"
          :key="item.key"
          :show-sidebar="showSidebar"
          :icon-left="item.icon"
          :to="item.to"
        >
          {{ $t(`pages.${item.key}`) }}
        </MenuItem>
        <div class="group flex items-center">
          <a
            href="https://app.vespertool.com/"
            target="_blank"
            class="relative flex w-full items-center text-sm"
          >
            <div
              class="relative flex size-7 shrink-0 items-center justify-center rounded-md text-vesper-purple group-hover:bg-gray-300"
              :class="{
                'mr-[23px]': showSidebar || sidebarHover,
              }"
            >
              <Icon
                icon="fa-regular fa-arrow-up-right-from-square"
                fixed-width
              />
            </div>

            <div
              v-if="sidebarHover || showSidebar"
              class="text-vesper-blue/50 group-hover:text-vesper-purple"
            >
              {{ $t(`pages.vesper-tool`) }}
            </div>
          </a>
        </div>
        <ReportABugButton
          :sidebar-hover="sidebarHover"
          :show-sidebar="showSidebar"
        />
      </div>
    </nav>
    <div class="mt-auto flex border-t border-vesper-blue/5 px-[22px]">
      <button
        class="group flex items-center py-[17px]"
        @click="toggleSidebar()"
      >
        <div
          class="mr-[19px] flex size-7 items-center justify-center rounded-full bg-vesper-blue/10"
        >
          <Icon
            :icon="
              sidebarPinned
                ? 'fa-regular fa-angle-left'
                : 'fa-regular fa-angle-right'
            "
            alt="Toggle menu icon"
            fixed-width
            class="text-sm text-gray-500"
          />
        </div>
        <div
          v-if="showSidebar"
          class="text-sm text-vesper-blue/50 group-hover:text-vesper-purple"
        >
          {{ sidebarPinned ? $t(`common.collapse`) : $t(`common.expand`) }}
        </div>
      </button>
    </div>
  </aside>
  <PanelCreateListing />
</template>

<script setup lang="ts">
import CreateOrderMenuItem from '@/components/CreateOrderMenuItem.vue';
import Icon from '@/components/Icon.vue';
import MenuItem from '@/components/MenuItem.vue';
import PanelCreateListing from '@/components/PanelCreateListing.vue';
import ReportABugButton from '@/components/ReportABugButton.vue';
import { useProductStore, useSidebarStore } from '@/stores';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import { LocationAsRelativeRaw, useRoute } from 'vue-router';

const sidebarStore = useSidebarStore();
const productStore = useProductStore();
productStore.load();

const { lg } = useBreakpoints(breakpointsTailwind);

watch(
  () => lg.value,
  () => {
    if (!lg.value && sidebarPinned.value) {
      sidebarHover.value = false;
      sidebarStore.sidebarPinned = false;
    }
  }
);
const route = useRoute();

watch(
  () => route.params.productSlug,
  () => {
    const product =
      productStore.findBySlug(route.params.productSlug) ?? productStore.product;

    if (product) {
      productStore.setActiveProduct(product);
    }
  }
);

type MenuItemConfig = {
  icon?: string;
  key: string;
  to: LocationAsRelativeRaw;
};

const mainNavItems: MenuItemConfig[] = [
  {
    icon: 'fa-regular fa-arrow-right-arrow-left',
    key: 'deals',
    to: { name: 'deals' },
  },
];

const supportNavItems: MenuItemConfig[] = [
  {
    icon: 'fa-regular fa-message-question',
    key: 'help-and-support',
    to: { name: 'help-and-support' },
  },
  {
    icon: 'fa-regular fa-user',
    key: 'my-account',
    to: { name: 'my-account' },
  },
];

const sidebarHover = ref(false);
const sidebarPinned = computed(() => sidebarStore.sidebarPinned);
const showSidebar = computed(() => sidebarPinned.value || sidebarHover.value);

function toggleSidebar() {
  if (sidebarPinned.value) {
    sidebarHover.value = false;
    sidebarStore.sidebarPinned = false;
  } else {
    sidebarStore.sidebarPinned = true;
  }

  localStorage.setItem(
    'sidebar-pinned',
    sidebarPinned.value ? 'true' : 'false'
  );
}

const sidebarWidth = computed(() => {
  return showSidebar.value ? 'w-[233px]' : 'w-[72px]';
});

const productIconMap: Record<string, string> = {
  butter: 'fa-regular fa-butter',
  'skimmed-milk-powder': 'fa-regular fa-ball-pile',
};

function getIcon(slug: string) {
  return productIconMap[slug];
}
</script>

<style scoped>
.sidebar-bounce {
  transition-timing-function: cubic-bezier(0.2, 0, 0.2, 1.5);
}
</style>
